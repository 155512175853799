import React, { useContext } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import { Login } from '../components/Login/Login';
import { Home } from '../components/Home/Home';
import { Navbar } from '../components/global/Navbar/Navbar';
import { Rodape } from '../components/global/Rodape/Rodape';
import { Faq } from '../components/menu/Faq/Faq';
import { Tutoriais } from '../components/menu/Tutoriais/Tutoriais';
import { Documentos } from '../components/menu/Documentos/Documentos';
import { Painel } from '../components/menu/Painel/Painel';
import { Diferenciais } from '../components/menu/Diferenciais/Diferenciais';
import { Midias } from '../components/menu/Midias/Midias';
import { Financeiro } from '../components/menu/Financeiro/Financeiro';
import { Calendarios } from '../components/menu/Calendarios/Calendarios';

import { AuthProvider, AuthContext } from '../contexts/auth';

const AppRoutes = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) {
      return <div className="loading">Carregando...</div>
    }

    if (!authenticated) {
      return <Navigate to='/login' />
    }

    return children;
  };

  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route exact path='/login' element={<Login />} />

          <Route exact path='/' element={<Private><Home /></Private>} />

          <Route exact path='/painel' element={<Private><Painel /></Private>} />
          <Route exact path='/faq' element={<Private><Faq /></Private>} />
          <Route exact path='/tutoriais' element={<Private><Tutoriais /></Private>} />
          <Route exact path='/documentos' element={<Private><Documentos /></Private>} />
          <Route exact path='/financeiro' element={<Private><Financeiro /></Private>} />
          <Route exact path='/diferenciais' element={<Private><Diferenciais /></Private>} />
          <Route exact path='/midias' element={<Private><Midias /></Private>} />
          <Route exact path='/calendarios' element={<Private><Calendarios /></Private>} />
        </Routes>
        <Rodape />
      </AuthProvider>

    </Router>
  );
}

export default AppRoutes;
