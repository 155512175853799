import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/auth";

import {
  findAllChamados,
  findChamadoPolo,
  deleteChamado,
  updateChamado,
  findNextPage,
  findNextPagePolo,
} from "../../../services/axios";

import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from "@mui/material";

export const Solicitacoes = () => {
  const { user } = useContext(AuthContext);
  const [accordion, setActiveAccordion] = useState(-1);
  const [numPages, setNumPages] = useState([]);
  const [chamados, setChamados] = useState([]);
  const [current, setCurrent] = useState(0);

  const deletarChamado = async (id) => {
    const confirm = window.confirm('Tem certeza que deseja apagar o chamado?');
    if (confirm) {
      try {
        await deleteChamado(id);
      } catch (err) {
        console.log('err');
      }
    }
  };

  const atualizarChamado = async (id) => {
    const confirm = window.confirm('Tem certeza que deseja concluir o chamado?');
    if (confirm) {
      try {
        await updateChamado(id);
      } catch (err) {
        console.log('err');
      }
    }
  }

  const skipPage = async (page) => {
    let chamados = [];
    if (user.tipoUser === 'NEAD') {
      chamados = await findNextPage(page);
    } else if (user.tipoUser === 'Atendimento') {
      chamados = await findNextPage(page);
    } else if (user.tipoUser === 'polo') {
      chamados = await findNextPagePolo(page);
    } else {
      chamados = [];
    }

    setCurrent(page);
    setChamados(chamados);
  };

  useEffect(() => {
    const findChamados = async () => {
      let chamados = [];
      if (user.tipoUser === 'NEAD') {
        chamados = await findNextPage(current);
      } else if (user.tipoUser === 'Atendimento') {
        chamados = await findNextPage(current);
      } else if (user.tipoUser === 'polo') {
        console.log(user.cnpj);
        chamados = await findNextPagePolo(current, user.cnpj);
      } else {
        chamados = [];
      }
      return setChamados(chamados);
    };
    findChamados();
  }, [chamados]);

  useEffect(() => {
    const findNumPages = async () => {
      try {
        let chamados = [];
        if (user.tipoUser === 'polo') {
          chamados = await findChamadoPolo(user.cnpj);
          console.log(chamados);
        } else if (user.tipoUser === 'Atendimento') {
          chamados = await findAllChamados();

        } else if (user.tipoUser === 'NEAD') {
          chamados = await findAllChamados();

        } else {
          chamados = [];
        }

        const itemsIndex = [];
        let numPage = (Math.ceil(chamados.length)) / 5;

        let i = 0;
        while (numPage > 0) {
          i++
          itemsIndex.push(i);
          numPage--;
        }
        setNumPages(itemsIndex.length);

      } catch (err) {
        console.log(err);
      }
    };

    findNumPages();

  }, []);

  const theme = createTheme({
    palette: {
      text: {
        primary: '#fff',
      },
    },
  });
  
  function toggleAccordion(i) {
    if (i === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(i);
  }

  return (
    <>
      <div id="solicitacoes">
        <div><p>Código</p></div>
        <div>Categoria</div>
        <div>Assunto</div>
        <div>Data</div>
        <div>Status</div>
        <div>Alterar</div>
      </div>

      <div>
        <div className="accordion_faq">
          {chamados.map((item, i) => (
            <div key={i} className="total">
              <div className="accordion_faq-heading">
                <div className={accordion === i ? "active" : ""} id="results">
                  <div id="result-item1"><span className="name-moblile cod">Código: </span>{item.protocolo}</div>
                  <div id="result-item2"><span className="name-moblile cat">Categoria: </span>{item.categoria}</div>
                  <div id="result-item3"><span className="name-moblile ass">Assunto: </span>{`${item.descricao.slice(0, 12)}...`}</div>
                  <div id="result-item4"><span className="name-moblile data">Data: </span>{item.data}</div>
                  <div id="result-item5"><span className="name-moblile stts">Status: </span>{item.status}</div>

                  {user.tipoUser === 'NEAD' && (
                    <div id="gerir">
                      <button className="trash d-inline" onClick={() => deletarChamado(item.idchamado)}><i className="bi bi-trash3"></i></button>
                      <button className="check d-inline" onClick={() => atualizarChamado(item.idchamado)}><i className="bi bi-check-lg"></i></button>
                    </div>
                  )}

                  {user.tipoUser === 'Atendimento' && (
                    <div className="gerir">
                      <button className="check me-4" onClick={() => atualizarChamado(item.idchamado)}><i className="bi bi-check-lg"></i></button>
                    </div>
                  )}

                  {user.tipoUser === 'polo' && (
                    <div className="gerir">
                      <button className="hash me-4"><i className="bi bi-hash"></i></button>
                    </div>
                  )}

                </div>
              </div>
              <div>
                {accordion === i ? (<>
                  <span className="verticle"><i className="bi bi-chevron-up" onClick={() => toggleAccordion(i)}></i></span>
                </>) : (<>
                  <span className="verticle"><i className="bi bi-chevron-down" onClick={() => toggleAccordion(i)}></i></span></>)}
              </div>
              <div className="response">
                <div className={accordion === i ? "active" : "inactive"}>
                  <div className="response-text d-block">
                    <p><span className="content">Nome do solicitante: </span>{item.nome_solicitante}</p>
                    <p><span className="content">Polo: </span>{item.nome}</p>
                    <p><span className="content">E-mail:</span> {item.email}</p>
                    <p><span className="content">Categoria da Solicitação: </span>{item.categoria}</p>
                    <p><span className="content">Nome completo do Aluno: </span>{item.nome_aluno}</p>
                    <p><span className="content">Descrição: </span>{item.descricao}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <ThemeProvider theme={theme}>
          <Pagination
            onChange={(event, page) => {
              const pageFormated = page - 1;
              console.log(page);
              skipPage((page === 1 ? pageFormated : (page - 1) * 5));
            }}
            count={numPages}
            color="secondary"
            variant="outlined"
            sx={{ color: "text.primary" }}
          /></ThemeProvider>
      </div>
    </>
  );
}
