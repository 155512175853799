import React, { useContext } from "react";
import { AuthContext } from "../../../contexts/auth";
import { Link } from "react-router-dom";
import logo from '../../../img/logo.png';
import './styleNavbar.css'

export const Navbar = () => {
  const { authenticated, logout, user } = useContext(AuthContext);
  if (!user) {
    return <></>;
  }

  if (user.tipoUser === 'NEAD') {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-light" id="navbar">
        <div className="container-fluid">

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <img src={logo} alt="" width="60" className="me-5" />
              </li>
              <li className="nav-item">
                <Link to="/painel" className="nav-link active link-dark link-menu" aria-current="page"><i className="bi bi-toggles2"></i><span className="ms-1">Administrativo</span></Link>
              </li>

              <li className="nav-item">
                <Link to="/" className="nav-link active link-dark link-menu" aria-current="page"><span className="ms-1">Home</span></Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link link-dark link-menu mr-n1" to="/faq">FAQ</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-dark link-menu" to="/tutoriais">Tutoriais</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-dark link-menu" to="/documentos">Documentos</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-dark link-menu" to="/calendarios">Calendarios</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-dark link-menu" to="/financeiro">Financeiro</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-dark link-menu" to="/diferenciais">Diferenciais</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link link-dark link-menu" to="/midias">Mídias</Link>
              </li>
            </ul>
            <ul className="navbar-nav">
              <li className="nv-item">
                {authenticated && (
                  <button onClick={() => logout()} className="button">Sair</button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-light" id="navbar">
      <div className="container-fluid">

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <img src={logo} alt="" width="60" className="me-5" />
            </li>

            <li className="nav-item">
              <Link to="/" className="nav-link active link-dark link-menu" aria-current="page"><span className="ms-1">Home</span></Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link link-dark link-menu mr-n1" to="/faq">FAQ</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link link-dark link-menu" to="/tutoriais">Tutoriais</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link link-dark link-menu" to="/documentos">Documentos</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link link-dark link-menu" to="/calendarios">Calendarios</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link link-dark link-menu" to="/financeiro">Financeiro</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link link-dark link-menu" to="/diferenciais">Diferenciais</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link link-dark link-menu" to="/midias">Mídias</Link>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nv-item">
              {authenticated && (
                <button onClick={() => logout()} className="button">Sair</button>
              )}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
