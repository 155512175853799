import React, { useState } from "react";
import topoMidias from './img/topo_midias.png'
import { Container } from "./styled";

export const Midias = () => {
  const [accordion, setActiveAccordion] = useState(-1);
  // const [accordionInterno, setActiveAccordionInterno] = useState(-1);

  function toggleAccordion(i) {
    if (i === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(i);
  }

  // function toggleAccordionInterno(i) {
  //   if (i === accordionInterno) {
  //     setActiveAccordionInterno(-1);
  //     return;
  //   }
  //   setActiveAccordionInterno(i);
  // }

  return (
    <Container className="my-container">
      <div className="topo-documentos">
        <img src={topoMidias} className="img-fluid" />
      </div>

      <div className="my-container">
        {/* ACORDEÃO 1 */}
        <div className="accordion_faq">
          <div key={1} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 1 ? "active" : ""}>Campanhas de Marketing</h3>
            </div>
            <div>
              {accordion === 1 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(1)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(1)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 1 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1gvEg0aYfHN7hpNt2Y5-_ACg9_OXQzOQ9" target="_blank" rel="noreferrer" className="ms-3">EAD 2023.1</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 1 */}
        <div className="divisor"></div>
      </div>
    </Container>
  );
}
