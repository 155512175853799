import React, { useState } from "react";
import topoCalendarios from './img/topo_calendarios.png'
import { Container } from "./styled";

export const Calendarios = () => {
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionInterno, setActiveAccordionInterno] = useState(-1);

  function toggleAccordion(i) {
    if (i === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(i);
  }

  function toggleAccordionInterno(i) {
    if (i === accordionInterno) {
      setActiveAccordionInterno(-1);
      return;
    }
    setActiveAccordionInterno(i);
  }

  return (
    <Container className="my-container">
      <div className="topo-documentos">
        <img src={topoCalendarios} className="img-fluid" />
      </div>

      <div className="my-container">

        {/* Acordeão 2 */}
        <div className="accordion_faq">
          <div key={2} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 2 ? "active" : ""}>Caléndarios Acadêmicos</h3>
            </div>
            <div>
              {accordion === 2 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(2)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(2)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <div className={accordion === 2 ? "active" : "inactive"}>

                {/* INTERNO 1 */}
                <div className="interno accordion_faq ">
                  <div className="w-70">
                    <div key={2.1} className="total" onClick={() => toggleAccordionInterno(2.1)} >
                      <div className="accordion_faq-heading-interno">
                        <h3 className={accordionInterno === 2.1 ? "active" : ""}>Agosto 2022.3</h3>
                      </div>
                      <div>
                        {accordionInterno === 2.1 ? (<>
                          <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                        </>) : (<>
                          <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                      </div>
                      <div>
                        <p className={accordionInterno === 2.1 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1dGgLl6aNJFXfIMeaJMTRTVU4jcziNZ0p/view?usp=share_link" target="_blank" rel="noreferrer">Calendario Academico EAD 2022.3 </a> </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* INTERNO 2 */}

                <div className="interno accordion_faq">
                  <div key={2.2} onClick={() => toggleAccordionInterno(2.2)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 2.2 ? "active" : ""}>Outubro 2022.4</h3>
                    </div>
                    <div>
                      {accordionInterno === 2.2 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 2.2 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1uZTqjQNlRsMA8nXNNBXEgz5PPjjobwoe/view?usp=share_link" target="_blank" rel="noreferrer">Calendário Acadêmico EAD 2022.1.4</a> </p>
                    </div>
                  </div>
                </div>

                {/* INTERNO 3 */}

                <div className="interno accordion_faq">
                  <div key={2.3} onClick={() => toggleAccordionInterno(2.3)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 2.3 ? "active" : ""}>Fevereiro 2023.1.1</h3>
                    </div>
                    <div>
                      {accordionInterno === 2.3 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 2.3 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1zwqNU-VbMZh3_yaEGaPvD54skaqZVEg4/view?usp=share_link" target="_blank" rel="noreferrer">Calendario Academico ead 2023.1.1</a> </p>
                    </div>
                  </div>
                </div>

                {/* INTERNO 4 */}
                <div className="interno accordion_faq">
                  <div key={2.4} onClick={() => toggleAccordionInterno(2.4)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 2.4 ? "active" : ""}>Abril 2023.1.2</h3>
                    </div>
                    <div>
                      {accordionInterno === 2.4 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 2.4 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1BrxbhNCdX0RnR6z4AhoHgioTmLyH1FI8/view?usp=share_link" target="_blank" rel="noreferrer">Calendario Academico ead 2023.1.2</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 5 */}

                <div className="interno accordion_faq">
                  <div key={2.5} onClick={() => toggleAccordionInterno(2.5)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 2.5 ? "active" : ""}>Agosto 2023.1.3</h3>
                    </div>
                    <div>
                      {accordionInterno === 2.5 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 2.5 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1TWG-qreKINEo8HFrwOU1p-MV7a59u4Kk/view?usp=share_link" target="_blank" rel="noreferrer">Calendario Academico ead 2023.1.3</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 6 */}

                <div className="interno accordion_faq">
                  <div key={2.6} onClick={() => toggleAccordionInterno(2.6)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 2.6 ? "active" : ""}>Outubro 2023.1.4</h3>
                    </div>
                    <div>
                      {accordionInterno === 2.6 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 2.6 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1zS_X4ACA6Va6_lTaGdteTOjrniN0jb08/view?usp=share_link" target="_blank" rel="noreferrer">Calendario Academico ead 2023.1.4</a> </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* FIM ACORDEÃO 2 */}
        <div className="divisor"></div>
      </div>
    </Container>
  );
}
