import React from "react";
import './styleRodape.css';
import { Footer } from './styled.js';
import logo from '../../../img/logo-branco.png';

export const Rodape = () => {

  return (
    <Footer className="fixed-buttom" id="rodape">
      <div className="content">
          <img src={logo} alt="" />
          <div className="mt-3">
            Desenvolvido por Núcleo de Educação a Distância UNIG &copy; 2023 <br/>
            Todos os direitos reservados.
          </div>
      </div>
    </Footer>
  )
}
