import styled from "styled-components";
import { opacColor, warningColor } from "../../../config/colors";

export const Container = styled.div`
  .bi-file-earmark-pdf-fill {
    color: #b00c04;
    font-size: 50px;
  }
  color: #fff;
  .img-tutoriais {
    margin-top: -70px;
  }
  .titulo-tutorial {
    border-bottom: 2px solid ${warningColor};
  }
  div.body {
    margin-top: 50px;
    color: #fff;
  }

  .videos {
    display: flex;
    background-color: ${opacColor};
    margin-bottom: 40px;
    box-shadow: 5px 5px 5px #1b1b1e;
  }

  .bg-vid {
    width: 530px;
    background-color: ${opacColor};
    margin-bottom: 40px;
    box-shadow: 5px 5px 5px #1b1b1e;
  }

  .vid-text {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .video {
    padding: 30px;
    width: 530px;
    height: 360px;
  }

  .video2 {
    margin-left: -30px;
  }

  @media (max-width: 768px) {
    .video {
      width: 100%;
    }

    .videos {
      flex-wrap: wrap;
    }
    .video2 {
      margin-left: 0px;
    }
  }
`;
