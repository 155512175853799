import React, { useState } from "react";
import { Main } from "./styled";
import topoFaq from "./img/topo_faq.png"

export const Faq = () => {
  const [accordion, setActiveAccordion] = useState(-1);

  function toggleAccordion(i) {
    if(i === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(i);
  }

  return (
    <Main id="faq" className="my-container">
      <img src={topoFaq} className="img-fluid img-faq" />
      <div className="accordion_faq">
        {faqs.map((item, i) => (
          <div key={i} onClick={() => toggleAccordion(i)} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === i ? "active" : ""}>{item.question}</h3>
            </div>
            <div>
              {accordion === i ? (<>
                <span className="verticle"><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === i ? "active" : "inactive"}>{item.resposta}</p>
            </div>
          </div>
        ))}
      </div>
    </Main>
  );
}

const faqs = [
  {
    question: 'Quais são os canais de suporte disponíveis para os polos?',
    resposta: 'Os únicos canais oficiais de suporte são: WhatsApp (comunicação com o NEAD) e abertura de chamado (comunicação com o financeiro), via portal de suporte.',
  },
  {
    question: 'Qual o prazo médio para a conclusão de uma solicitação via WhatsApp (NEAD)?',
    resposta: 'O primeiro retorno ocorre em aproximadamente em 15 minutos e o prazo para resolução do problema vai variar de acordo com sua complexidade. Porém, o responsável pelo suporte deve fornecer um prazo após analisar a situação.',
  },
  {
    question: 'Como ocorre a divulgação do calendário das aulas presenciais?',
    resposta: 'O calendário das aulas presenciais é fornecido durante a aula inaugural e também informado aos alunos pelo recurso de mensagens, no ambiente virtual de aprendizagem. O polo poderá ajudar nessa divulgação consultando os calendários disponíveis neste portal de suporte.',
  },
  {
    question: 'O que acontece se um aluno ficar reprovado em 3 ou mais disciplinas no período?',
    resposta: 'O aluno terá que refazer o período, que possui valor fechado, independentemente da quantidade de disciplinas que estará cursando (mínimo de 3 disciplinas).'
  },
  {
    question: 'Qual o contato para agendamento do tour guiado pelo campus Nova Iguaçu?',
    resposta: 'Esse tipo de agendamento é feito com o Alan Mesquita, responsável pelo polo Nova Iguaçu, através do número: 2127654087.',
  },
  {
    question: 'As provas são realizadas online ou no polo?',
    resposta: 'Atualmente, de acordo com a orientação do MEC, as provas são realizadas online.',
  },
  {
    question: 'Todas as disciplinas são ofertadas em videoaulas ou é somente em PDF?',
    resposta: 'As disciplinas ofertadas pela UNIG são compostas por: vídeos (média de 7 min), conteúdos textuais, gráficos, exercícios, atividades práticas e conteúdo textual em formato PDF (livro).',
  },
  {
    question: 'Quanto tempo o tutor leva para responder aos alunos?',
    resposta: 'O tutor pode levar no máximo 48 horas para dar retorno aos alunos, através da caixa de mensagens do ambiente virtual de aprendizagem.',
  },
  {
    question: 'Qual o prazo médio para retorno de uma solicitação de isenção de disciplinas?',
    resposta: 'O prazo máximo são 15 dias, mas, esse retorno geralmente ocorre antes.',
  },
];
