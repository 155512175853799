import styled from "styled-components";
import { primaryDarkColor, seccondaryColor, successColor, warningColor } from "../../../config/colors";

export const Container = styled.div`
  color: #fff;
  .MuiPagination-text {
      color: #fff;
    }

  #pagination {
    color: #fff;
  }
  .ant-pagination-simple-pager {
  .ant-pagination-slash {
    visibility: hidden;
  }

  .ant-pagination-item-link {
    color: #fff;
    background-color: blue;
  }

  span::after {
    content: 'de';
    margin-left: 0;
    visibility: visible;
  }
}

  div.img-topo {
    margin-top: -60px
  }

  h2 {
    font-weight: 900;
    margin-bottom: 10px;
    border-bottom: 2px solid ${warningColor};
  }

  div.solicitacoes {
    margin-top: 40px;
    width: 90%;
    font-weight: 900;
  }

  .name-moblile {
    display: none;
  }
  
  @media (max-width: 1000px) {
    #results {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    #result-item2, #result-item3, #result-item4, #result-item5 {
      margin-left: -20px;
    }

    #solicitacoes {
      display: none;
      font-size: 0px;
    }

    .name-moblile {
      display: inline;
      font-weight: bold;
    }

    .cat {
      margin-left: -35px;
    }
    .ass {
      margin-left: 5px;
    }
    .data {
      margin-left: -5px;
    }
    .stts {
      margin-left: -35px;
    }
  }

  div#solicitacoes, #results{
    display: flex;
    justify-content: space-evenly;
  }

  #results {
    justify-content: space-between;
    width: 85%;
    margin-left: 8%;
  }

  #result-item1 {
    margin-left: 5px;
  }
  #result-item2 {
    margin-left: 40px;
  }
  #result-item3 {
    margin-left: 0px;
    margin-right: 0;
  }
  #result-item4 {
    margin-left: 10px;
    margin-right: 0;
  }
  #result-item5 {
    margin-left: 40px;
  }
  #gerir {
    margin-left: 40px;
  }
  
  .accordion-simple > .active {
    display: block;
  }

  span.content {
    color: #ac7434;
  }

  .accordion_faq .inactive{
    display: none;
  }

  .accordion_faq > div{
    padding: 0px 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ba3585;
  }

  .accordion_title{
    color: #fff;
    font-weight: 500;
  }

  .accordion_faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .accordion_faq-heading .active{
    color: #fff;
  }

  .accordion_faq-heading h3{
    color: #fff;
    font-weight: 600;
  }

  .accordion_faq p {
    margin: 0px;
    color: #fff;
    line-height: 1.4;
  }
  .verticle {
    margin-left: -1%;
    position: relative;
    top: -40px;
    font-size: 20px;
  }
  .response-text {
    margin-top: -40px;
    padding: 5%;
  }
  .trash {
    color: ${warningColor};
  }
  .check {
    color: ${successColor};
  }
  .hash {
    color: ${primaryDarkColor};
  }
`;

export const Form = styled.div`
  color: #fff;
  h2 {
    margin-top: 60px;
    font-weight: 900;
    margin-bottom: 10px;
    border-bottom: 2px solid ${warningColor};
  }
  .input {
    width: 100%;
    padding: 7px;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    color: #fff;
  }

  .campo {
     margin-top: 20px;
  }

  .confirm {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 15px;
  }

  select {
    height: 40px;
  }

  select option {
    margin: 400px;
    background-color: #201c2c;
    color: #fff;

  }

  button {
    background-color: ${seccondaryColor};
  }
`;
