import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

import { AuthContext } from '../../contexts/auth';

import {
  findComunicadoDp,
  findComunicadoPolo,
  findAllComunicados,
  deleteComunicado,
  findComunicadoPoloGlobal
} from '../../services/axios';

import img from './img/header.jpg';

import { Header, Avisos } from './styled';
import { AtalhosComp } from './Atalhos';
import { UpdateCad } from '../UpdateCad';

export const Home = () => {
  const [comunicados, setComunicados] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const getComunicados = async () => {
      try {
        if (user.tipoUser === 'polo') {
          const response = await findComunicadoPolo(user);
          const response2 = await findComunicadoPoloGlobal();

          const data2 = response2.data;
          const { data } = response;

          data2.map(dado => data.push(dado));
          return setComunicados(data);

        } else if (user.tipoUser === 'Atendimento') {
          const response = await findComunicadoDp();
          const { data } = response;
          return setComunicados(data);
        } else if (user.tipoUser === 'NEAD') {
          const response = await findAllComunicados();
          const { data } = response;
          return setComunicados(data);
        } else {
          return setComunicados([{
            id: 1,
            titulo: 'Um erro foi encontrado.',
            mensagem: 'Relate o erro à equipe NEAD imediatamente, para que o erro seja ajustado o mais rápido possível.',
          }]);
        }
      } catch (err) {
        console.log(err.request);
        setComunicados([{
          id: 1,
          titulo: 'Um erro foi encontrado.',
          mensagem: 'Relate o erro à equipe NEAD imediatamente, para que o erro seja ajustado o mais rápido possível.',
        }]);
      }
    }

    getComunicados();
  }, [comunicados]);

  const deleteComunicados = async (id) => {
    const confirm = window.confirm('Tem certeza que deseja apagar o comunicado?');
    if (confirm) {
      try {
        await deleteComunicado(id);
      } catch (err) {
        console.log(err.request);
      }
    }
  }

  return (
    <div>
      {user.senha === '123456' &&(
        <UpdateCad />
      )}
      <Header className="my-container">
        <img src={img} className='img-fluid' id="image" />
      </Header>

      <Avisos className="my-container">
        <div>
          <h2>Avisos Importantes</h2>
          <ol>
            {comunicados.map(comunicado => (
              <div key={comunicado.idcomunicado} className="grid-avisos">
                <div className="calendar">
                  <p className="d-flex"><i className="bi bi-calendar2-event-fill me-2"></i>{comunicado.data}</p>
                  <p><i className="bi bi-clock-fill me-2"></i>{comunicado.hora}</p>
                </div>
                <li>
                  <div className="alerta">
                    <h3>{comunicado.titulo}</h3>
                    <p>{comunicado.mensagem}</p>
                    {user.tipoUser === 'NEAD' && (
                      <button className="trash" onClick={() => deleteComunicados(comunicado.idcomunicado)}><i className="bi bi-trash3"></i></button>
                    )}
                  </div>
                </li>
              </div>
            ))}
          </ol>
        </div>
      </Avisos>
      <AtalhosComp />
    </div>
  );
}
