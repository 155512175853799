import styled from "styled-components";
import { seccondaryColor, primaryDarkColor } from "../../config/colors";

export const Header = styled.div`
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  #image {
    box-shadow: 5px 5px 5px #1b1b1e;
  }
  p {
    color: #fff;
    margin-left: 5%;
    flex: 1;
    align-self: flex-end;
  }
`;

export const Avisos = styled.div`
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background-color: #fff;
  padding: 10px;
  box-shadow: 5px 5px 5px #1b1b1e;

  h2 {
    width: 93%;
    margin-left: 34px;
    font-size: 37pt;
    border-bottom: 1px solid #ba3585;
    margin-bottom: 20px;
    padding: 8px;
  }  
  
  div.grid-avisos {
    display: flex;
    margin-bottom: 15px;
  }

  div.calendar {
    background-color: ${primaryDarkColor};
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  div.alerta {
    background-color: ${primaryDarkColor};
    min-width: 755px;
    max-width: 755px;
    min-height: 120px;
    padding: 13px;
    padding-bottom: 5px;
    padding-top: 20px;

    @media (max-width: 1200px) {
      min-width: 630px;
      max-width: 90%;
    }

    @media (max-width: 900px) {
      min-width: 530px;
      max-width: 90%;
    }

    @media (max-width: 850px) {
      min-width: 450px;
      max-width: 90%;
    }

    @media (max-width: 800px) {
      min-width: 350px;
      max-width: 90%;
    }

    @media (max-width: 750px) {
      min-width: 250px;
      max-width: 90%;
    }

    @media (max-width: 600px) {
      min-width: 160px;
      max-width: 90%;
    }
  }

  li {
    list-style-type: none;
  }

  .bi-calendar2-event-fill {
    color: #ba3585;
  }
  .bi-clock-fill {
    color: #2e4cd0;
  }
  .bi-trash3 {
    cursor: pointer;
    color: #ba3585;
  }
  button.trash {
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    left: 97%;
    top: 5px;
  }
  
  @media (max-width: 1200px) {
    button.trash {
      left: 94%;
    }
  }
`;

export const Atalhos = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    min-height: 200px;
    color: #fff;

    div.beneficios-container p {
      color: #fff;
      font-size: 10pt;
      width: 450px;
      margin-top: 10px;
    }

    img.beneficios {
      min-width: 450px;
      margin-right: 50px;
      max-height: 700px;
      box-shadow: 5px 5px 5px #1b1b1e;
      transition: all 0.5s;
      cursor: pointer;
    }

    @media (max-width: 1200px) {
      img.beneficios {
        width: 510px;
      }
    }

    @media (max-width: 650px) {
      .beneficios-container {
        margin-left: -5px;
        max-width: 300px;
      }
    }
    @media (max-width: 500px) {
      .beneficios-container {
        margin-left: -5px;
      }
      img.beneficios {
        max-width: 30px;
      }
    }

    img.beneficios:hover {
      -webkit-transform: scale(0.1);
      transform: scale(0.94);
    }

    div.grid-interno {
      display: flex;
      flex-direction: column;
      margin-right: -6%;
      flex-wrap: wrap;

      a {
          text-decoration: none;
          color: #fff;
        }

      img.financeiro {
        width: 510px;
        box-shadow: 5px 5px 5px #1b1b1e;
        transition: all 0.5s;
        cursor: pointer;
      }

      img.financeiro:hover {
        -webkit-transform: scale(0.1);
        transform: scale(0.94);
      }

      p.txt-financeiro {
        margin-top: 10px;
        width: 490px;
        font-size: 10pt;
      }

      div.wpp {
        background-color: ${seccondaryColor};
        max-width: 510px;
        min-height: 280px;
        margin-top: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 5px 5px #1b1b1e;
        transition: all 0.5s;
        cursor: pointer;

        p {
          margin-left: 10px;
        }

      }

      div.wpp:hover {
        -webkit-transform: scale(0.1);
        transform: scale(0.94);
      }
    }
`;
