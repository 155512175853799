import styled from "styled-components";

export const Container = styled.div`
  color: #fff;
  a { 
    display: block;
    margin-top: -30px;
    color: #ac7434;
    text-decoration: none;
  }
  a:hover {
    color:#ac7500;
  }
  
  .topo-documentos {
    margin-top: -60px;
    margin-bottom: 60px;
  }

  .accordion-simple > .active {
    display: block;
  }

  .accordion_faq .inactive{
    display: none;
  }

  .interno {
    width: 30%;
    margin-left: 50px;
    .verticle {
    position: relative;
    top: -40px;
    right: 100%;
    left: -120%;
    font-size: 30px;
  }
  }

  .divisor {
    border-bottom: 1px solid #ba3585;
    margin-bottom: 40px;
  }

  .accordion_faq > div{
    margin-bottom: 20px;
    padding: 0px 15px;
    cursor: pointer;
  }

  .accordion_title{
    color: #fff;
    padding-bottom: 20px;
    font-weight: 500;
  }

  .accordion_faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .accordion_faq-heading-interno h3{
    color: #fff;
    font-size: 15pt;
    font-weight: 400;
  }

  .accordion_faq p {
    margin: 0px;
    padding-bottom: 30px;
    color: #fff;
    line-height: 1.4;
  }

  .verticle {
    margin-left: 99%;
    position: relative;
    top: -50px;
    font-size: 30px;
  }
`;
