import styled from "styled-components";
import { primaryColor } from "../../config/colors";

export const Update = styled.section`
  .efeito-vidro {
    background: rgba( 0, 0, 0, 0.35 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border: 1px solid rgba( 0, 0, 0, 0.18 );
    min-height: 100vh;
    position: fixed;
    z-index: 2;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }

  .validate {
    margin-top: 10px;
  }

  .error {
    color: #ED4337;
  }

  .success {
    color: #5CB85C;
  }

  #form {
    background-color: ${primaryColor};
    color: #fff;
    position: absolute;
    left: 30%;
    right: 30%;
    z-index: 10;
    min-width: 600px;
    min-height: 600px;
    padding: 20px;
    box-shadow: 5px 5px 5px #1b1b1e;

    input {
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      padding: 5px;
      width: 90%;
      color: #fff;
      box-shadow: 5px 5px 5px #1b1b1e;
    }

    .btn {
      width: 100px;
      background-color: rgba(200,200,200,0.1);
      margin-top: 10px;
      color: #fff;
      box-shadow: 5px 5px 5px #1b1b1e;
    }

    .send {
      margin-top: 15px;
    }

    .label {
      margin-bottom: -2px;
      margin-top: 10px;
    }
  }

`;
