import styled from 'styled-components';

export const Container = styled.div`
  color: #fff;
  h2 {
    font-weight: 900;
  }

  .fundo {
    position: absolute;
    z-index: -1;

    .fundo1 {
      margin-left: -60px;
    }
    .fundo2 {
      position: relative;
      left: 100%;
      bottom: 100px;
    }
  }

  .videoInstalacoes {
    width: 90%;
  }

  .tutoresEspecialistas {
    width: 90%;
    margin-bottom: 50px;
  }

  div.img-diferenciais {
    margin-top: -60px;
  }

  span {
    color: #ac7434;
  }

  div.intro {
    margin-bottom: 60px;
  }

  div.body {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 1030px) {
      display: block;
      h2, p {
        min-width: 500px;
      }
      .flex-item {
        width: 100%;
        margin-bottom: 30px;
      }
      .justify {
        width: 100%;
      }
    }

    .flex-item {
      width: 50%;
      margin-bottom: 30px;
    }
    .justify {
      width: 46%;
      margin-right: 4%;
    }

    img {
      box-shadow: 5px 5px 5px #1b1b1e;
    }
  }

`;
