import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://api.suporte.ead.e-unig.com.br',
});

export const createSession = async (email, senha) => {
  return api.post('/tokens', { email, senha });
};

export const findPolos = async () => {
  return api.get('/polos');
};

export const setComunicado = async (titulo, mensagem, idpolo, iddepartamento, global) => {
  return api.post('/comunicados', {
    titulo,
    mensagem,
    idpolo,
    iddepartamento,
    global,
  });
};

export const findComunicadoPolo = async (cnpj) => {
  return api.post('/comunicados/findByPolo', { cnpj });
};

export const findComunicadoPoloGlobal = async () => {
  return api.get('/comunicados/findByPoloGlobal');
};

export const findComunicadoDp = async () => {
  return api.post('/comunicados/findByDp');
};

export const findAllComunicados = async () => {
  return api.get('/comunicados/findAll');
};

export const deleteComunicado = async (id) => {
  return api.delete(`/comunicados/${id}`);
};

export const setChamado = (nomeSolicitante, email, nomeAluno, descricao, status, protocolo, categoria, idPolo, idDepartamento) => {
  return api.post('/chamados', {
    nomeSolicitante,
    email,
    nomeAluno,
    descricao,
    status,
    protocolo,
    categoria,
    idPolo,
    idDepartamento
  });
};

export const findChamadoPolo = async (cnpj) => {
  const chamados = await api.post('/chamados/findByPolo', { cnpj });
  return chamados.data;
};

export const findChamadoDp = async () => {
  const chamados = await api.post('/chamados/findByDp');
  return chamados.data;
};

export const findAllChamados = async () => {
  const chamados = await api.get('/chamados/findAll');
  return chamados.data;
};

export const deleteChamado = async (id) => {
  return api.delete(`/chamados/${id}`);
};

export const updateChamado = async (id) => {
  return api.put(`/chamados/${id}`);
};

export const findNextPage = async (offset) => {
  const chamados = await api.get(`/chamados/findPage/${offset}`);
  return chamados.data;
};

export const findNextPageDp = async (offset) => {
  const chamados = await api.get(`/chamados/findPageDp/${offset}`);
  return chamados.data;
};

export const findNextPagePolo = async (offset, cnpj) => {
  const chamados = await api.post(`/chamados/findPagePolo/${offset}`, { cnpj });
  return chamados.data;
};

export const updateCad = async (dados) => {
  return api.put('/updateCad', dados);
};
