import styled from 'styled-components';
import { seccondaryColor } from '../../../config/colors';

export const Container = styled.div`
  color: #fff;
  button {
    background-color: #581e44
  }
  h1 {
    border-bottom: 1px solid ${seccondaryColor};
  }
  .topo-documentos {
    margin-top: -60px;
    margin-bottom: 60px;
  }
  .campo {
    background-color: rgba(0,0,0,0.3);
    width: 80%;
    border: none;
    padding: 5px;
    color: #fff;
  }
`;
