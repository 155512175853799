import styled from "styled-components";

export const Form = styled.form`
  .confirm {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 15px;
    width: 80%;
  }
  select {
    height: 40px;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    color: #fff;
    padding: 5px;
  }

  select option {
    margin: 400px;
    background-color: #201c2c;
    color: #fff;

  }
`;
