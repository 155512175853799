import React, { useState, useEffect } from 'react';
import { findPolos, setComunicado } from '../../../../services/axios';

import { Form } from './styled';
import { toast } from 'react-toastify';

export const Comunicados = () => {
  const [titulo, setTitulo] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [destino, setDestino] = useState('');
  const [idpolo, setIdPolo] = useState(1);
  const [dados, setDados] = useState(null);
  const [polos, setPolos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [global, setGlobal] = useState(0);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    const resgatarPolos = async () => {
      try {
        const allPolos = await findPolos();
        setPolos(allPolos.data);
        setLoading(false);
      } catch (err) {
        return setPolos({
          message: 'Um erro ocorreu ao buscar os polos'
        });
      }
    }

    clear();

    resgatarPolos();
  }, []);

  const clear = () => {
    setTitulo('');
    setMensagem('');
    setIdPolo(1);
    setDados(null);
    setDestino('');
    setGlobal(0);
  }

  async function criarSolicitacao(event) {
    event.preventDefault();

    if (!titulo || !mensagem || !destino) {
      return toast.error('Preencha todos os campos!');
    }

    if (destino === 4) {
      setDados({
        titulo,
        mensagem,
        iddepartamento: 4,
        idpolo: null,
        global,
      });
    } else if (destino === 2){
      setDados({
        iddepartamento: null,
        idpolo,
        titulo,
        mensagem,
        global,
      });
    } else {
      setDados({
        iddepartamento: null,
        idpolo: null,
        titulo,
        mensagem,
        global,
      });
    }

    if (!dados) {
      return setConfirm(true);
    }

    try {
      await setComunicado(dados);
      toast.success('Comunicado enviado com sucesso');
    } catch (err) {
      console.log(dados, 'error');
      toast.error('Erro ao enviar o comunicado');
    }
  }

  if (loading) {
    return <div className="loading">Carregando...</div>
  }

  return (
    <div className="my-container" id="comunicados">
      <h2>Abrir Comunicado ou Aviso</h2>

      <p>Abra um comunicado com os polos ou com o Financeiro UNIG:</p>

      <Form>
        <fieldset>
          <h5 className="mt-4">A quem se destina a sua mensagem?</h5>
          <label htmlFor="comunicadosFin" className="me-2">Atendimento Financeiro</label>
          <input
            type="radio"
            id="comunicadosFin"
            onClick={() => setDestino(4)}
            name="destino"
            className="me-5"
          />

          <label htmlFor="comunicadosPolo" className="me-2">Polo </label>
          <input
            type="radio"
            id="comunicadosPolo"
            onClick={() => setDestino(2)}
            name="destino"
          />
          <label htmlFor="comunicadosPolo" className="ms-5 me-2">Todos os polos</label>
          <input
            type="radio"
            id="comunicadosPolo"
            onClick={() => {
              setDestino(3);
              setGlobal(1);
            }}
            name="destino"
          />
          {destino === 2 && (
            <select
              name="polo"
              id="filtrarPolos"
              className="d-block mt-3"
              onChange={(e) =>
                setIdPolo(parseInt(e.target.value))}>
              {polos.map((polo) => (
                <option value={polo.idpolo} key={polo.idpolo}>{polo.nome}</option>
              ))}
            </select>
          )}
        </fieldset>

        <br />

        <p><label htmlFor="nome" className="form-label">Título</label><br />
          <input
            type="text"
            value={titulo}
            id="nome"
            onChange={(e) => setTitulo(e.target.value)}
            className="campo"
            required
          /></p>

        <p><label htmlFor="descricao">Mensagem: </label><br />
          <textarea name="descricao" value={mensagem} id="descricao" cols="20" rows="2" className="campo" onChange={(e) => setMensagem(e.target.value)}></textarea></p>
        {!confirm ? (
          <button
            onClick={(event) => {
              criarSolicitacao(event);
            }}
            className="mb-3">Enviar</button>
        ) : (
          <div className="confirm">
            <p>Confirma o envio do comunicado? </p>
            <button
              onClick={(event) => {
                criarSolicitacao(event);
                setConfirm(false);
                clear();
              }}
              className="mb-3">Confirmar</button>
          </div>
        )}
      </Form>
    </div>
  );
}
