import React from "react";
import { Container } from "./styled";

import topoDiferenciais from './img/topo_diferenciais.png';
import eventosPresenciais from './img/eventos_presenciais.jpg';
import carteirinha from './img/carteirinha.jpg';
import tutoresEspecialistas from './img/tutoresEspecialistas.jpg';
import certificacaoparcial from './img/certificacaoparcial.jpg';

import fundo1 from './img/fundo1.png';
import fundo2 from './img/fundo2.png';

export const Diferenciais = () => {
  return (
    <Container className="my-container" id="diferenciais">
      <div className="img-diferenciais">
        <img src={topoDiferenciais} className="img-fluid" />
      </div>

      <div className="intro my-container">
        <p><span>A sensação de pertencimento</span> é a motivação chave para a maioria das confirmações de matrícula. Os nossos candidatos, em sua maioria, já conhecem a UNIG e sonham com a vivência em nosso ambiente universitário. <span>Nossa missão</span> é mostrar para esses potenciais alunos, que mesmo na modalidade a distância, eles poderão viver esse <span>sonho</span>. O aluno EAD, assim com o aluno da modalidade presencial, faz parte do corpo discente UNIG e possui <span>diferenciais</span> que devem ser destacados no momento da venda:
        </p>
      </div>

      <div className="body my-container">
        <div className="flex-item justify">
          <h2>Acesso às Instalações</h2>
          <p>O aluno EAD tem acesso às instalações do campus! Ele pode utilizar os laboratórios e acessar à biblioteca, incluindo o empréstimo de livros. Para facilitar essa familiarização e localização de pontos de interesse em nossas dependências, o aluno tem a opção de agendar um passeio guiado, onde ele será apresentado à estrutura da UNIG.</p>
        </div>
        <div className="flex-item">
          <iframe className="videoInstalacoes" width="" style={{ height: 250 }} src="https://www.youtube.com/embed/JrScc2AnSWE" title="UNIG | Campus Nova Iguaçu" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        <div className="flex-item">
          <img src={eventosPresenciais} className="img-fluid" />
        </div>
        <div className="flex-item">
          <h2>Eventos presenciais</h2>
          <p>Durante a <span>aula inaugural</span> o aluno conhecerá o coordenador de seu curso e terá acesso à informações valiosas que serão úteis durante toda sua vida acadêmica. No <span>Integra NEAD</span>, os alunos terão a oportunidade de realizar apresentações de seus trabalhos. A cada semestre letivo o aluno conta com atividades Extensionistas de grande relevância para sua formação acadêmica, podemos destacar a <span>Semana Intercurso</span> que tem como principal objetivo permitir a intercursuralidade no processo de formação de forma efetiva, promovendo palestras nacionais e internacionais com profissionais de renome nas diversas áreas de formação dos nossos cursos.  Já na <span>SEPEX</span> (Semana de Ensino, Pesquisa e Extensão), o aluno terá acesso a várias palestras relevantes para seu curso.</p>
        </div>
        <div className="flex-item justify">
          <h2>Carteirinha</h2>
          <p>Em breve nossa carteirinha será digital. A equipe de TI do NEAD está preparando essa novidade para os nossos alunos! Através de um app que será disponibilizado na Google Play e App Store, será possível gerar carteirinha de estudante em poucos passos. Com o documento, o aluno tem 50% de desconto em ingressos para cinemas, teatros, espetáculos musicais, eventos educativos, jogos de futebol, entre muitos outros.</p>
        </div>

        <div className="flex-item">
          <img src={carteirinha} className="img-fluid" />
        </div>

        <div className="flex-item">
          <img src={tutoresEspecialistas} className="img-fluid tutoresEspecialistas" />
        </div>

        <div className="flex-item justify">
          <h2>Tutores Especialistas</h2>
          <p>Nenhum dos nossos tutores é generalista como na maioria das instituições concorrentes. Na UNIG, todos eles são especialistas em suas áreas de atuação, garantindo assim um direcionamento muito mais eficaz.</p>
        </div>

        <div className="flex-item justify">
          <h2>Certificações Parciais</h2>
          <p>
            Na UNIG o aluno não precisa esperar a conclusão do curso para dar um upgrade no currículo. Ao longo de sua tragetória acadêmica, ele recebe certificações parciais que farão toda a diferença para o mercado de trabalho. Vale ressaltar, que para obter uma certificação parcial o aluno precisa estar aprovado em 100% dos TGs, na conclusão dos períodos indicados. A lista das certificações parciais pode ser encontrada na seção documentos do portal.
          </p>
        </div>

        <div className="flex-item">
          <img src={certificacaoparcial} className="img-fluid" />
        </div>

      </div>

      <div className="fundo">
        <img src={fundo1} className="fundo1" />
        <img src={fundo2} className="fundo2" />
      </div>

      <div className="my-container conclusao">
        <h2>Nota máxima no MEC</h2>
        <p>O EAD da UNIG é autorizado pelo MEC com avaliação máxima (5). Nossa insituição foi credenciada por 10 anos para ofertar cursos a distância. Toda a operação EAD, incluindo as estratégias de mediação do processo de ensino apredizagem passaram por essa avaliação. Vale ressaltar que, diferentemente da concorrência, onde os tutores são generalistas, atuando basicamente como suporte técnico para o uso de ferramentas de mediação, nossos tutores possuem formação específica em suas áreas de atuação, permitindo dessa forma, que o esclarecimentos e orientações sobre o conteúdo sejam fornecidos durante o processo de tutoria.</p>
      </div>
      <div className="p-5"></div>
    </Container>
  );
}
