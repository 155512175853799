import React, { useState } from "react";
import topoDocumentos from './img/topo_documentos.png'
import { Container } from "./styled";

export const Documentos = () => {
  const [accordion, setActiveAccordion] = useState(-1);
  const [accordionInterno, setActiveAccordionInterno] = useState(-1);

  function toggleAccordion(i) {
    if (i === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(i);
  }

  function toggleAccordionInterno(i) {
    if (i === accordionInterno) {
      setActiveAccordionInterno(-1);
      return;
    }
    setActiveAccordionInterno(i);
  }

  return (
    <Container className="my-container">
      <div className="topo-documentos">
        <img src={topoDocumentos} className="img-fluid" />
      </div>

      <div className="my-container">

        {/* ACORDEÃO 13 */}
        <div className="accordion_faq">
          <div key={130} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 130 ? "active" : ""}>Certificação Parcial</h3>
            </div>
            <div>
              {accordion === 130 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(130)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(130)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 130 ? "active" : "inactive"}><a href="https://docs.google.com/spreadsheets/d/1U2OF9mcG0yPc4IdO2pY-GJzTZV-x-E8t/edit?usp=share_link&ouid=111010182993301073665&rtpof=true&sd=true" target="_blank" rel="noreferrer" className="ms-3">Certificação Parcial</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 13 */}
        <div className="divisor"></div>

        {/* Acordeão 1 */}
        <div className="accordion_faq">
          <div key={1} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 1 ? "active" : ""}>Editais</h3>
            </div>
            <div>
              {accordion === 1 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(1)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(1)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <div className={accordion === 1 ? "active" : "inactive"}>

                {/* INTERNO 1 */}
                <div className="interno accordion_faq ">
                  <div className="w-70">
                    <div key={1.1} className="total" onClick={() => toggleAccordionInterno(1.1)} >
                      <div className="accordion_faq-heading-interno">
                        <h3 className={accordionInterno === 1.1 ? "active" : ""}>Vestibular ENEM EAD 2023.1</h3>
                      </div>
                      <div>
                        {accordionInterno === 1.1 ? (<>
                          <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                        </>) : (<>
                          <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                      </div>
                      <div>
                        <p className={accordionInterno === 1.1 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/10hn4wtuK22el2n-iKwJG3jW9ArnCrjCi/view?usp=share_link" target="_blank" rel="noreferrer">ADITIVO_EDITAL_VESTIBULAR_ENEM_EAD_2023_1</a> </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* FIM INTERNO 1 */}

                {/* INTERNO 2 */}
                <div className="interno accordion_faq">
                  <div key={2.2} onClick={() => toggleAccordionInterno(2.2)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 2.2 ? "active" : ""}>2023 - EAD 2ª Entrada</h3>
                    </div>
                    <div>
                      {accordionInterno === 2.2 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 2.2 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1cMPX7xkHyhV2ghBNq4DcVtHoWQIO-26f/view?usp=share_link" target="_blank" rel="noreferrer">Edital_2023_EAD_2ª_ENTRADA_FINAL</a> </p>
                    </div>
                  </div>
                </div>
                {/* FIM INTERNO 2 */}

                {/* INTERNO 3 */}
                <div className="interno accordion_faq">
                  <div key={1.2} onClick={() => toggleAccordionInterno(1.3)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 1.3 ? "active" : ""}>Transferência_e_Reingresso_Segunda_Entrada</h3>
                    </div>
                    <div>
                      {accordionInterno === 1.3 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 1.3 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1KHu6nlGqyqMzA4SstpRTbriw0CLNnd_C/view?usp=share_link" target="_blank" rel="noreferrer">Edital_2023_EAD_2ª_ENTRADA_FINAL</a> </p>
                    </div>
                  </div>
                </div>
                {/* FIM INTERNO 3 */}

              </div>
            </div>
          </div>
        </div>

        {/* FIM ACORDEÃO 1 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 2 */}

        <div className="accordion_faq">
          <div key={40} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 40 ? "active" : ""}>Estruturas Curriculares </h3>
            </div>
            <div>
              {accordion === 40 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(40)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(40)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <div className={accordion === 40 ? "active" : "inactive"}>

                {/* INTERNO 1 */}
                <div className="interno accordion_faq ">
                  <div className="w-70">
                    <div key={3.1} className="total" onClick={() => toggleAccordionInterno(3.1)} >
                      <div className="accordion_faq-heading-interno">
                        <h3 className={accordionInterno === 3.1 ? "active" : ""}>Administração</h3>
                      </div>
                      <div>
                        {accordionInterno === 3.1 ? (<>
                          <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                        </>) : (<>
                          <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                      </div>
                      <div>
                        <p className={accordionInterno === 3.1 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/12X1hcSpzMU80Jd-PlFPMctnr2SrImARt/view?usp=share_link" target="_blank" rel="noreferrer">Administração</a> </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* INTERNO 2 */}

                <div className="interno accordion_faq">
                  <div key={3.2} onClick={() => toggleAccordionInterno(3.2)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.2 ? "active" : ""}>ADS</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.2 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.2 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/12jM13JCKEB0vYtdHppUqzEcig4_ZG8nY/view?usp=share_link" target="_blank" rel="noreferrer">ADS</a> </p>
                    </div>
                  </div>
                </div>

                {/* INTERNO 3 */}

                <div className="interno accordion_faq">
                  <div key={3.3} onClick={() => toggleAccordionInterno(3.3)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.3 ? "active" : ""}>Biomedicina</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.3 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.3 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1yLBPGGtwKJTigbaYAEF_tsc-vFauLULH/view?usp=share_link" target="_blank" rel="noreferrer">Biomedicina</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 4 */}

                <div className="interno accordion_faq">
                  <div key={3.4} onClick={() => toggleAccordionInterno(3.4)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.4 ? "active" : ""}>Ciência_da_Computação</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.4 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.4 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1PU4GTU2mg3YRCmOjQPMiumBQawuThR_o/view?usp=share_link" target="_blank" rel="noreferrer">Ciência da Computação</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 5 */}

                <div className="interno accordion_faq">
                  <div key={3.5} onClick={() => toggleAccordionInterno(3.5)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.5 ? "active" : ""}>Ciências Contábeis</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.5 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.5 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1vUYa2hfTPVl9raAVdfoJWn-ajbSX3goj/view?usp=share_link" target="_blank" rel="noreferrer">Ciências Contábeis</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 6 */}

                <div className="interno accordion_faq">
                  <div key={3.6} onClick={() => toggleAccordionInterno(3.6)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.6 ? "active" : ""}>Ciências Biológicas</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.6 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.6 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1aKdQX7w9FRziRu0UtChDIzvmmI-a_IUR/view?usp=share_link" target="_blank" rel="noreferrer">Ciências Biológicas</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 7 */}

                <div className="interno accordion_faq">
                  <div key={3.7} onClick={() => toggleAccordionInterno(3.7)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.7 ? "active" : ""}>Comércio Exterior</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.7 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.7 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1n2xjAsLnN_3ruPQbtK7jbQgnYUP3f1Vm/view?usp=share_link" target="_blank" rel="noreferrer">Comércio Exterior</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 8 */}

                <div className="interno accordion_faq">
                  <div key={3.8} onClick={() => toggleAccordionInterno(3.8)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.8 ? "active" : ""}>Engenharia de Produção</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.8 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.8 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1-_ZwXoKaWXj9qock9YzI1FugaUIpGyMS/view?usp=sharing" target="_blank" rel="noreferrer">Engenharia de Produção</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 9 */}

                <div className="interno accordion_faq">
                  <div key={3.9} onClick={() => toggleAccordionInterno(3.9)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.9 ? "active" : ""}>Engenharia Elétrica</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.9 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.9 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1FSGxy8eed-8jYd4fVaGQ5zRypQxYg242/view?usp=sharing" target="_blank" rel="noreferrer">Engenharia Elétrica</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 10 */}

                <div className="interno accordion_faq">
                  <div key={3.10} onClick={() => toggleAccordionInterno(3.10)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.10 ? "active" : ""}>Engenharia Mecânica</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.10 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.10 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1mAw5089AsJMpULJ9kgaBpIrXR5K1YmXc/view?usp=share_link" target="_blank" rel="noreferrer">Engenharia Mecânica</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 11 */}

                <div className="interno accordion_faq">
                  <div key={3.11} onClick={() => toggleAccordionInterno(3.11)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.11 ? "active" : ""}>Estética e Cosmética</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.11 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.11 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/10j_RKKOnlaPzdHOvgP0z_VjZHqSLuBbq/view?usp=sharing" target="_blank" rel="noreferrer">Estética e Cosmética</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 12 */}

                <div className="interno accordion_faq">
                  <div key={3.12} onClick={() => toggleAccordionInterno(3.12)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.12 ? "active" : ""}>Farmácia</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.12 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.12 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1Ex8Ym89tDH2Y5F3g8coVwZWfgb--Y4Hg/view?usp=share_link" target="_blank" rel="noreferrer">Farmácia</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 13 */}

                <div className="interno accordion_faq">
                  <div key={3.13} onClick={() => toggleAccordionInterno(3.13)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.13 ? "active" : ""}>Fisioterapia</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.13 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.13 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1dDtH2I3f_aJIm6kzv-iGibBrryWE3dSW/view?usp=sharing" target="_blank" rel="noreferrer">Fisioterapia</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 14 */}

                <div className="interno accordion_faq">
                  <div key={3.14} onClick={() => toggleAccordionInterno(3.14)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.14 ? "active" : ""}>Gestão Financeira</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.14 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.14 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1MR-KcblNHZf1rfL-K9Q8bAsdcEnRPZau/view?usp=sharing" target="_blank" rel="noreferrer">Gestão Financeira</a> </p>
                    </div>
                  </div>
                </div>

                {/* INTERNO 15 */}

                <div className="interno accordion_faq">
                  <div key={3.15} onClick={() => toggleAccordionInterno(3.15)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.15 ? "active" : ""}>Gestão Comercial</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.15 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.15 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1jGKfCUsl3PjfYuBU7_9DNoDqPsqFNHZ_/view?usp=share_link" target="_blank" rel="noreferrer">Gestão Comercial</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 16 */}

                <div className="interno accordion_faq">
                  <div key={3.16} onClick={() => toggleAccordionInterno(3.16)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.16 ? "active" : ""}>Gestão Hospitalar</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.16 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.16 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1uQCez2IO-YLnBdZTKnBLnLyt-tAqEn7k/view?usp=share_link" target="_blank" rel="noreferrer">Gestão Hospitalar</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 17 */}

                <div className="interno accordion_faq">
                  <div key={3.17} onClick={() => toggleAccordionInterno(3.17)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.17 ? "active" : ""}>Gestão_Produção_Industrial</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.17 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.17 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1a5C60u6ZCJzgSRoV7bQhyJH_zPsy5rJg/view?usp=sharing" target="_blank" rel="noreferrer">Gestão_Produção_Industrial</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 18 */}

                <div className="interno accordion_faq">
                  <div key={3.18} onClick={() => toggleAccordionInterno(3.18)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.18 ? "active" : ""}>Gestão Pública</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.18 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.18 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1XtJdCSdTKc43Zwo_PPZcfwE5JtWE1s6h/view?usp=share_link" target="_blank" rel="noreferrer">Gestão Pública</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 19 */}

                <div className="interno accordion_faq">
                  <div key={3.19} onClick={() => toggleAccordionInterno(3.19)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.19 ? "active" : ""}>Logística</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.19 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.19 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1VsBm2tk5hjeX0j6MhJjb2-EHoMt_jViG/view?usp=sharing" target="_blank" rel="noreferrer">Logística</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 20 */}

                <div className="interno accordion_faq">
                  <div key={3.20} onClick={() => toggleAccordionInterno(3.20)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.20 ? "active" : ""}>Marketing</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.20 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.20 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1-X40ujw3GkpRGJ6uvjaV_5X7xQML7tIi/view?usp=share_link" target="_blank" rel="noreferrer">Marketing</a> </p>
                    </div>
                  </div>
                </div>

                {/* INTERNO 21 */}

                <div className="interno accordion_faq">
                  <div key={3.21} onClick={() => toggleAccordionInterno(3.21)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.21 ? "active" : ""}>Pedagogia</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.21 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.21 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/13Z_-Rl_N3s-IvynDtcYgKiBWMu_tHSph/view?usp=share_link" target="_blank" rel="noreferrer">Pedagogia</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 22 */}

                <div className="interno accordion_faq">
                  <div key={3.22} onClick={() => toggleAccordionInterno(3.22)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.22 ? "active" : ""}>Processos Gerenciais</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.22 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.22 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1VXI9PeuF60jwlLFAMYZPOnZY3aoaeB1I/view?usp=sharing" target="_blank" rel="noreferrer">Processos Gerenciais</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 23 */}

                <div className="interno accordion_faq">
                  <div key={3.23} onClick={() => toggleAccordionInterno(3.23)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.23 ? "active" : ""}>Recursos Humanos</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.23 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.23 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1fg28u1FrVrK9NNBwA_q6D8T_32ilrFvk/view?usp=share_link" target="_blank" rel="noreferrer">Recursos Humanos</a> </p>
                    </div>
                  </div>
                </div>



                {/* INTERNO 24 */}

                <div className="interno accordion_faq">
                  <div key={3.24} onClick={() => toggleAccordionInterno(3.24)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.24 ? "active" : ""}>2ª_Licenciatura_Pedagogia</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.24 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.24 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1W6Hwl59nRIQ9IzTFrAAybjxbcAv0jdfq/view?usp=sharing" target="_blank" rel="noreferrer">2ª_Licenciatura_Pedagogia</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 21 */}

                <div className="interno accordion_faq">
                  <div key={3.25} onClick={() => toggleAccordionInterno(3.25)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.25 ? "active" : ""}>Segurança Pública</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.25 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.25 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1kZyA1trpyjBGqd_NL5uy6Zk4BdDHE1kZ/view?usp=share_link" target="_blank" rel="noreferrer">Segurança Pública</a> </p>
                    </div>
                  </div>
                </div>


                {/* INTERNO 26 */}

                <div className="interno accordion_faq">
                  <div key={3.26} onClick={() => toggleAccordionInterno(3.26)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.26 ? "active" : ""}>Serviço Social</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.26 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.26 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1ZP6WK0XmR-z_aSfkgpSS9uaKvvUdkpHz/view?usp=share_link" target="_blank" rel="noreferrer">Serviço Social</a> </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 2 */}


        <div className="divisor"></div>


        {/* ACORDEÃO 2.1 */}
        <div className="accordion_faq">
          <div key={60} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 60 ? "active" : ""}>Sistema de Avaliação</h3>
            </div>
            <div>
              {accordion === 60 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(60)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(60)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 60 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1qdfmrLiQiLRjUhAcR1K4Fz-3ZXKuc1IJ/view?usp=share_link" target="_blank" rel="noreferrer" className="ms-3">Sistema de Avaliação</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 2.1 */}


        <div className="divisor"></div>


        {/* ACORDEÃO 2.2 */}
        <div className="accordion_faq">
          <div key={70} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 70 ? "active" : ""}>Atividades Complementares</h3>
            </div>
            <div>
              {accordion === 70 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(70)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(70)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 70 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/14olP9JJ90w4bAHgXfRduK81riWrlW_TQ/view?usp=share_link" target="_blank" rel="noreferrer" className="ms-3">Atividades Complementares</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 2.2 */}


        <div className="divisor"></div>


        {/* ACORDEÃO 2.3 */}
        <div className="accordion_faq">
          <div key={80} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 80 ? "active" : ""}>Períodos dos Cursos</h3>
            </div>
            <div>
              {accordion === 80 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(80)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(80)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 80 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1OPQb1ks-97lpUtUUY5F7WH8LXUhPZ_RW/view?usp=share_link" target="_blank" rel="noreferrer" className="ms-3">Períodos dos Cursos</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 2.3 */}


        <div className="divisor"></div>


        {/* ACORDEÃO 3 */}
        <div className="accordion_faq">
          <div key={30} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 30 ? "active" : ""}>Apresentação Reunião Geral</h3>
            </div>
            <div>
              {accordion === 30 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(30)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(30)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 30 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1eoXn5ShCO6HLBvpzlNN5VSKv1db8jFtL/view?usp=share_link" target="_blank" rel="noreferrer" className="ms-3">APRESENTAÇÃO DIA 18/07</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 3 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 3 */}

        <div className="accordion_faq">
          <div key={3} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 3 ? "active" : ""}>Edital e Termos Aditivos </h3>
            </div>
            <div>
              {accordion === 3 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(3)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(3)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <div className={accordion === 3 ? "active" : "inactive"}>

                {/* INTERNO 1 */}
                <div className="interno accordion_faq ">
                  <div className="w-70">
                    <div key={3.1} className="total" onClick={() => toggleAccordionInterno(3.1)} >
                      <div className="accordion_faq-heading-interno">
                        <h3 className={accordionInterno === 3.1 ? "active" : ""}>2022.3 - Edital Agosto</h3>
                      </div>
                      <div>
                        {accordionInterno === 3.1 ? (<>
                          <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                        </>) : (<>
                          <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                      </div>
                      <div>
                        <p className={accordionInterno === 3.1 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1vTo0LQcuamKOM8nAOC0JFd_3kXiSEsx_/view?usp=share_link" target="_blank" rel="noreferrer">Edital 2022 EAD Segundo Semestre ENEM</a> </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* INTERNO 2 */}

                <div className="interno accordion_faq">
                  <div key={3.2} onClick={() => toggleAccordionInterno(3.2)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 3.2 ? "active" : ""}>Termo Aditivo Outubro</h3>
                    </div>
                    <div>
                      {accordionInterno === 3.2 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 3.2 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1mKGRrnoSFjxBLcQcDvWMvEnhzTsUaFdQ/view?usp=share_link" target="_blank" rel="noreferrer">Termo Aditivo 6 - Quarta Entrada</a> </p>
                    </div>
                  </div>
                </div>

                {/* INTERNO 3 */}

                <div className="interno accordion_faq">
                  <div key={31.3} onClick={() => toggleAccordionInterno(31.3)} className="total">
                    <div className="accordion_faq-heading-interno">
                      <h3 className={accordionInterno === 31.3 ? "active" : ""}>Termos Aditivos 2022.3</h3>
                    </div>
                    <div>
                      {accordionInterno === 31.3 ? (<>
                        <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                      </>) : (<>
                        <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                    </div>
                    <div>
                      <p className={accordionInterno === 31.3 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1bBi_LrbB6z5rJj9BXrOYa33WR4_HB_uD/view?usp=share_link" target="_blank" rel="noreferrer">01_ADITIVO_2022_03</a> </p>
                      <p className={accordionInterno === 31.3 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1uOAWfzZXDXtpwANHvUHtb2DA3QYZHe5t/view?usp=share_link" target="_blank" rel="noreferrer" className="mt-1">02_ADITIVO_2022_03</a> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 3 */}

        <div className="divisor"></div>


        {/* ACORDEÃO 4 */}


        <div className="accordion_faq">
          <div key={4} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 4 ? "active" : ""}>E-mail para Matrícula - Modelo</h3>
            </div>
            <div>
              {accordion === 4 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(4)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(4)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <div className={accordion === 4 ? "active" : "inactive"}>

                {/* INTERNO 1 */}
                <div className="interno accordion_faq ">
                  <div className="w-70">
                    <div key={4.1} className="total" onClick={() => toggleAccordionInterno(4.1)} >
                      <div className="accordion_faq-heading-interno">
                        <h3 className={accordionInterno === 4.1 ? "active" : ""}>Documentos</h3>
                      </div>
                      <div>
                        {accordionInterno === 4.1 ? (<>
                          <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                        </>) : (<>
                          <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                      </div>
                      <div>
                        <p className={accordionInterno === 4.1 ? "active" : "inactive"}><a href="https://docs.google.com/document/d/15tAEZVsaiz89D7aQhY9D9ryICo6pUikp/edit?usp=share_link&ouid=115415851102731190907&rtpof=true&sd=true" target="_blank" rel="noreferrer">E-mail Assinatura de Contrato UNIG EAD </a> </p>
                        <p className={accordionInterno === 4.1 ? "active" : "inactive"}><a href="https://docs.google.com/document/d/1zb4TLzP-RMxvKdzt-amcmgpa1DZ86GGl/edit?usp=share_link&ouid=115415851102731190907&rtpof=true&sd=true" target="_blank" rel="noreferrer" className="mt-1">E-mail para Matricular </a> </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* FIM ACORDEÃO 4 */}
        <div className="divisor"></div>


        {/* ACORDEÃO 5 */}
        <div className="accordion_faq">
          <div key={5} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 5 ? "active" : ""}>Integra NEAD</h3>
            </div>
            <div>
              {accordion === 5 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(5)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(5)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <div className={accordion === 5 ? "active" : "inactive"}>

                {/* INTERNO 1 */}
                <div className="interno accordion_faq ">
                  <div className="w-70">
                    <div key={5.1} className="total" onClick={() => toggleAccordionInterno(5.1)} >
                      <div className="accordion_faq-heading-interno">
                        <h3 className={accordionInterno === 5.1 ? "active" : ""}>INTEGRA JUNHO 2022</h3>
                      </div>
                      <div>
                        {accordionInterno === 5.1 ? (<>
                          <span className="verticle"><i className="bi bi-chevron-up"></i></span>
                        </>) : (<>
                          <span className="verticle"><i className="bi bi-chevron-down"></i></span></>)}
                      </div>
                      <div>
                        <p className={accordionInterno === 5.1 ? "active" : "inactive"}><a href=" https://drive.google.com/drive/folders/1kxtpClqv7IQTZK-Wjhm0QysbmZjPK3oQ?usp=share_link" target="_blank" rel="noreferrer">ITAPERUNA - Programação </a> </p>
                        <p className={accordionInterno === 5.1 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1Epzz0x3r-lJCOV_AmgaMeXw3gHdU4wb6?usp=share_link" target="_blank" rel="noreferrer" className="mt-1">NOVA IGUAÇU - Programação </a> </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* FIM ACORDEÃO 5 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 6 */}
        <div className="accordion_faq">
          <div key={6} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 6 ? "active" : ""}>Manual do Aluno</h3>
            </div>
            <div>
              {accordion === 6 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(6)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(6)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 6 ? "active" : "inactive"}><a href="https://drive.google.com/file/d/1qsWHciQnXgehnXHo7xx3VggU3SUtb5lL/view?usp=share_link" target="_blank" rel="noreferrer" className="ms-3">MANUAL DO ALUNO</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 6 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 7 */}
        <div className="accordion_faq">
          <div key={7} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 7 ? "active" : ""}>Matrizes</h3>
            </div>
            <div>
              {accordion === 7 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(7)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(7)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 7 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1Xr4pInDqPQCIoRHuEoKIBq6CmhCaiqK1" target="_blank" rel="noreferrer" className="ms-3">MATRIZES</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 7 */}

        <div className="divisor"></div>


        {/* ACORDEÃO 8 */}
        <div className="accordion_faq">
          <div key={8} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 8 ? "active" : ""}>Portarias</h3>
            </div>
            <div>
              {accordion === 8 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(8)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(8)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 8 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/16cyhZfQwJ7aSodELpsL2pv2_fV9oAFyv" target="_blank" rel="noreferrer" className="ms-3">PORTARIAS</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 8 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 9 */}
        <div className="accordion_faq">
          <div key={9} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 9 ? "active" : ""}>Projetos Pedagógicos</h3>
            </div>
            <div>
              {accordion === 9 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(9)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(9)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 9 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1N2ihbcyggYf76-39TjFfwHjlmd-u9RWu" target="_blank" rel="noreferrer" className="ms-3">Projetos Pedagógicos</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 9 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 10 */}
        <div className="accordion_faq">
          <div key={10} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 10 ? "active" : ""}>Reingresso e Transferência</h3>
            </div>
            <div>
              {accordion === 10 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(10)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(10)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 10 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1DgPjlZ6sp8QGksr85f7E2wAnSZQntUaX" target="_blank" rel="noreferrer" className="ms-3">Reingresso e Transferência</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 10 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 11 */}
        <div className="accordion_faq">
          <div key={11} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 11 ? "active" : ""}>Resultado do Processo Seletivo</h3>
            </div>
            <div>
              {accordion === 11 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(11)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(11)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 11 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1DgY2HBGPOJhzUsL70RppbLvqTxctT0lT" target="_blank" rel="noreferrer" className="ms-3">Resultado do Processo Seletivo</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 11 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 12 */}
        <div className="accordion_faq">
          <div key={12} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 12 ? "active" : ""}>Tabelas de Preços</h3>
            </div>
            <div>
              {accordion === 12 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(12)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(12)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 12 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1882KQG_Az3UJ3daBXgQTXGXqqIY6VRmP" target="_blank" rel="noreferrer" className="ms-3">TABELAS</a> </p>
              {/* <p className={accordion === 12 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1lEmD2lmTil0LagynPaw71plXAlgi3eAP" target="_blank" rel="noreferrer" className="ms-3 mt-1">CARDS CURSOS</a> </p> */}
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 12 */}

        <div className="divisor"></div>

        {/* ACORDEÃO 13 */}
        <div className="accordion_faq">
          <div key={13} className="total">
            <div className="accordion_faq-heading">
              <h3 className={accordion === 13 ? "active" : ""}>Termo de Compromisso</h3>
            </div>
            <div>
              {accordion === 13 ? (<>
                <span className="verticle" onClick={() => toggleAccordion(13)} ><i className="bi bi-chevron-up"></i></span>
              </>) : (<>
                <span className="verticle" onClick={() => toggleAccordion(13)} ><i className="bi bi-chevron-down"></i></span></>)}
            </div>
            <div>
              <p className={accordion === 13 ? "active" : "inactive"}><a href="https://drive.google.com/drive/folders/1PlUEkZxJFwl86QT6oIEIzpsN6KYDHqep" target="_blank" rel="noreferrer" className="ms-3">Termo de Compromisso</a> </p>
            </div>
          </div>
        </div>
        {/* FIM ACORDEÃO 13 */}
        <div className="divisor"></div>

      </div>
    </Container>
  );
}
