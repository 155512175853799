import React from "react";
import { Container } from './styled';
import topoTutoriais from './img/topo_tutoriais.png'

export const Tutoriais = () => {

  return (
    <Container className="my-container" id="tutoriais">
      <div className="img-tutoriais">
        <img src={topoTutoriais} alt="" className="img-fluid" />
      </div>
      <div className="body">
        <h2 className="titulo-tutorial">Ambiente Virtual de Aprendizagem</h2>
        <div>
          <p className="vid-text">Principais recursos e funcionalidades.</p>
          <div className="videos">
            <iframe className="video" src="https://www.youtube.com/embed/iwlBYl2fB7k" title="Acesso" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

            <iframe className="video" src="https://www.youtube.com/embed/npaHuUIpe20" title="Acesso" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>

          <div className="videos">
            <iframe className="video" src="https://www.youtube.com/embed/FbZuvUQTJnY" title="Acesso" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

            <iframe className="video" src="https://www.youtube.com/embed/-cu5R8VDQVU" title="Acesso" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>

          <div className="bg-vid">
            <iframe className="video" src="https://fast.player.liquidplatform.com/pApiv2/embed/cee29914fad5b594d8f5918df1e801fd/88a4be0853e1cb5eed65a4dc9b54667c" title="Acesso" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          </div>

        </div>

        <h2 className="titulo-tutorial">Processo de Matrícula</h2>
        <div>
          <p className="vid-text">O processo de matrícula no Sophia foi dividido em 2 vídeos para facilitar o entendimento das etapas.</p>
          <div className="videos">
            <iframe className="video" src="https://drive.google.com/file/d/1-Z_BFMmrEXKCLw-xuQjkC1cC3zz6LH4d/preview" title="1  Sistema de Avaliação Graduação   100% 2021 2" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <iframe className="video video2" src="https://drive.google.com/file/d/1jGM621UqqlgsJA-HdSz0ODv1bFD0K4C7/preview" title="1  Sistema de Avaliação Graduação   100% 2021 2" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>

        <h2 className="titulo-tutorial">Versão do Tutorial em PDF</h2>

        <div className="bg-vid" style={{ display: 'flex', padding: 5}}>
          <div>
            <a href="https://drive.google.com/file/d/1XR9LSFys2381q3l6Hy8MuZ9XDfkLzMj7/view?usp=share_link" target="_blank" rel="noreferrer"><img src="" alt="" /><i className="bi bi-file-earmark-pdf-fill" ></i></a>
          </div>
          <div style={{ marginTop: 15, marginLeft: 5 }}>
            <span>Veja o passo a passo para matrícula em formato PDF. </span>
            <span>(Clique no ícone)</span>
          </div>
        </div>

      </div>
    </Container>
  );
}
