import styled from "styled-components";
import { seccondaryColor } from "../../../config/colors";

export const Footer = styled.footer`
  background-color: ${seccondaryColor};
  color: #fff;
  margin-top: 50px;
  min-height: 300px;
  padding: 80px;
  box-shadow: 5px -5px 5px #1b1b1e;
  font-size: 10pt;

  img {
    width: 150px;
  }

  div.content {
    margin-top: -20px;
    margin-left: 13%;
  }
`;
