import React, { useContext, useState } from "react";
import { Update } from "./styled";
import { AuthContext } from "../../contexts/auth";
import { toast } from "react-toastify";
import { updateCad } from "../../services/axios";
import { useNavigate } from 'react-router-dom';

export const UpdateCad = () => {
  const { user } = useContext(AuthContext);
  const [senha, setSenha] = useState('');
  const [repeatSenha, setRepeatSenha] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validate = () => {
      if (!senha) return { status: false, message: 'Campos obrigatórios: senha' };

      if (senha.length < 6) return { status: false, message: 'A senha precisa ter pelo menos 6 caracteres' };

      if (senha !== repeatSenha) return { status: false, message: 'As senhas precisam ser correspondentes' };

      if (senha === '123456') return { status: false, message: 'A senha deve ser diferente de 123456' }

      return { status: true, message: 'Informações corretas' };
    };

    const validado = validate();
    if (!validado.status) {
      return toast.error(validado.message);
    }

    const { email, idpessoa } = user;

    const dados = {
      idpessoa,
      email,
      senha,
    };
    try {
      await updateCad(dados)
      toast.success('Dados atualizados com sucesso, faça login novamente para continuar utilizando o sistema.');
      return setTimeout(() => {
        navigate('/login');
      }, 5000);
    } catch (err) {
      return toast.error('Ocorreu um erro ao atualizar o cadastro.');
    }
  };

  return (
    <>
      <Update>
        <div className="efeito-vidro"></div>
        <div id="form">
          <h1>Atualização Cadastral</h1>
          <p>Seja bem vindo!</p>
          <p>Para que você possa continuar utilizando o sistema, pedimos que preencha o formulário abaixo com a sua nova senha de acesso. Ao enviar, você será redirecionado para a tela de login, onde deverá entrar com o mesmo e-mail que utilizou para entrar aqui, mas a senha deverá ser a que você está cadastrando agora.</p>
          <p>ATENÇÃO! Não se esqueça da sua senha.</p>
          <p>Agradecemos a colaboração!</p>

          <form action="">

            <p className="label"><label htmlFor="senha">Nova senha: </label></p>
            <input
              type="password"
              name="senha" id="senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Preencha com a sua nova senha"
            />

            {senha && (
              <div>
                <div>
                  {senha.length < 6 || senha === '123456' ? (
                    <div>
                      <p className="validate error">A senha deve ter pelo menos 6 caracteres.</p>
                      <p className="validate error">A senha deve ser diferente de 123456.</p>
                    </div>
                  ) : (
                    <p className="validate success">A senha atende aos requisitos.</p>)
                  }
                </div>
              </div>
            )}

            <p className="label"><label htmlFor="repeatSenha">Repita a senha: </label></p>
            <input
              type="password"
              name="repeatSenha" id="repeatSenha"
              value={repeatSenha}
              onChange={(e) => setRepeatSenha(e.target.value)}
              placeholder="Repita a sua nova senha"
            />

            {repeatSenha && (
              <div>
                <div>
                  {repeatSenha !== senha ? (
                    <p className="validate error">As senhas precisam ser iguais!</p>
                  ) : (
                    <p className="validate success">As senhas são correspondentes.</p>)
                  }
                </div>
              </div>
            )}
          </form>

          <button className="btn send" onClick={(event) => handleSubmit(event)}>Enviar</button>
        </div>
      </Update>
    </>
  );
};
