import styled from "styled-components";
import { primaryColor } from "../../../config/colors";

export const Main = styled.div`
  background-color: ${primaryColor};
  margin: 0 auto;
  margin-top: -20px;
  color: #fff;

  img.img-faq {
    margin-bottom: 60px;
  }

  .accordion-simple > .active {
    display: block;
  }

  .accordion_faq .inactive{
    display: none;
  }

  .accordion_faq > div{
    margin-bottom: 20px;
    padding: 0px 15px;
    border-radius: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ba3585;
  }

  .accordion_title{
    color: #fff;
    padding-bottom: 20px;
  }

  .accordion_faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .accordion_faq-heading .active{
    color: #fff;
  }

  .accordion_faq-heading h3{
    font-weight: 10;
    color: #fff;
  }

  .accordion_faq p {
    margin: 0px;
    padding-bottom: 30px;
    color: #fff;
    line-height: 1.4;
  }

  .verticle {
    margin-left: 99%;
    position: relative;
    top: -50px;
    font-size: 30px;
  }
`;
