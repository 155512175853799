import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts/auth";

import { Container, Form } from "./styled";

import topoFinanceiro from './img/topo_financeiro.png';

import { Solicitacoes } from "./Solicitacoes";

import { findPolos, setChamado } from "../../../services/axios";
import { toast } from "react-toastify";

export const Financeiro = () => {
  const [polos, setPolos] = useState(null);
  const [loading, setLoading] = useState(true);
  const [idPolo, setIdPolo] = useState(1);
  const [nomeSolicitante, setNomeSolicitante] = useState('');
  const [email, setEmail] = useState('');
  const [nomeAluno, setNomeAuno] = useState('');
  const [descricao, setDescricao] = useState('');
  const [status] = useState('Pendente');
  const [categoria, setCategiria] = useState('');

  const { user } = useContext(AuthContext);

  const registrarChamado = async (e) => {
    e.preventDefault();
    if (!nomeSolicitante || !nomeAluno || !email || !descricao || !idPolo || !categoria) {
      return toast.error('Preencha todos os campos!');
    }
    const solicitacao = {
      nomeSolicitante,
      email,
      nomeAluno,
      descricao,
      status,
      categoria,
      idPolo,
      idDepartamento: 4,
    };

    try {
      await setChamado(solicitacao);
      toast.success('Solicitação criada com sucesso!');
      clear();
    } catch (err) {
      toast.err('Ocorreu um erro ao registrar a solicitação');
    }
  };

  const clear = () => {
    setNomeSolicitante('');
    setNomeAuno('');
    setEmail('');
    setDescricao('');
  };

  useEffect(() => {
    const resgatarPolos = async () => {
      try {
        const allPolos = await findPolos();
        setPolos(allPolos.data);
        setLoading(false);
      } catch (err) {
        return setPolos({
          message: 'Um erro ocorreu ao buscar os polos'
        });
      }
    }

    resgatarPolos();
  }, []);

  if (loading) {
    return <div className="my-container loading text-light">Carregando...</div>
  }

  if (user.tipoUser === 'polo' || user.tipoUser === 'NEAD') {
    return (
      <>
        <Container className="my-container">
          <div className="img-topo">
            <img src={topoFinanceiro} className="img-fluid" />
          </div>
          <div className="my-container mb-5">
            <h2>Seus chamados</h2>
            <p>Abaixo você confere os seus chamados. O setor financeirirá atualizar o status e responder as solicitações via e-mail. </p>
          </div>

          <Solicitacoes />
        </Container>

        <Form className="my-container">
          <h2 className="title">Abrir um chamado</h2>
          <p className="txt-intro">Preencha todos os campos para realizar uma solicitação de suporte diretamente para o setor Financeiro da UNIG.</p>
          <form action="">
            <div className="campo">
              <p><label htmlFor="">Nome do solicitante:</label></p>
              <p><input
                type="text"
                className="input"
                placeholder="Insira aqui seu nome"
                value={nomeSolicitante}
                onChange={(e) => setNomeSolicitante(e.target.value)}
              /></p>
            </div>

            <div className="campo">
              <p><label htmlFor="">E-mail:</label></p>
              <p><input
                type="email"
                name="email"
                id="email"
                className="input"
                autoComplete="off"
                value={email}
                placeholder="Insira aqui o e-mail para retorno de contato"
                onChange={(e) => setEmail(e.target.value)}
              /></p>
            </div>

            <div className="campo">
              <label htmlFor="filtrarPolos">Selecione a categoria da sua solicitação</label>
              <select
                name="polo"
                id="filtrarPolos"
                className="d-block mt-3 input"
                onChange={(e) => setCategiria(e.target.value)}
                defaultValue={'DEFAULT'}
              >
                <option disabled value="DEFAULT">Categoria</option>
                <option value='Boleto&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'>Boleto</option>
                <option value='Parcelamento'>Parcelamento</option>
                <option value='Vencimento'>Vencimento</option>
                <option value='Outros&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'>Outros</option>

              </select>
            </div>

            <div className="campo">
              <label htmlFor="filtrarPolos">Selecione o nome do polo que está abrindo a solicitação</label>
              <select
                name="filtrarPolos"
                id="filtrarPolos"
                className="d-block mt-3 input"
                onChange={(e) =>
                  setIdPolo(parseInt(e.target.value))}>
                {polos.map((polo) => (
                  <option value={polo.idpolo} key={polo.idpolo}>{polo.nome}</option>
                ))}
              </select>
            </div>

            <div className="campo">
              <p><label htmlFor="">Nome completo do aluno</label></p>
              <p><input
                type="text"
                className="input"
                placeholder="Nome completo do aluno"
                value={nomeAluno}
                onChange={(e) => setNomeAuno(e.target.value)}
              />
              </p>
            </div>

            <div className="campo">
              <p><label htmlFor="">Descrição</label></p>
              <p><textarea
                name=""
                id=""
                cols="30"
                rows="5"
                className="input"
                placeholder="Descreva de maneira sucinta o problema para ser enviado para o setor Financeiro"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              >
              </textarea></p>
            </div>
            <button
              onClick={(event) => {
                registrarChamado(event);
              }}
              className="mb-3">Enviar</button>
          </form>
        </Form>
      </>
    );
  }

  return (
    <>
      <Container className="my-container">
        <div className="img-topo">
          <img src={topoFinanceiro} className="img-fluid" />
        </div>
        <div className="my-container mb-5">
          <h2>Seus chamados</h2>
          <p>Abaixo você confere os seus chamados.Abaixo você confere os seus chamados. O setor de atendimento ficará responsável por receber e intermediar as solicitações com o financeiro. Assim que obtiver um retorno, o atendimento enviará sua resposta por e-mail. </p>
        </div>
        <Solicitacoes />
      </Container>
    </>
  );
}
