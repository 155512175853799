import React from "react"
import { Link } from "react-router-dom";
import { Atalhos } from "./styled";
import diferenciais from './img/diferenciais.jpg';
import whatsapp from './img/whatsapp.png';
import financeiro from './img/financeiro.jpg';

export const AtalhosComp = () => {
  return (
    <Atalhos className="my-container">
      <div className="beneficios-container">
        <Link to="/diferenciais"><img src={diferenciais} alt="diferenciais" className="beneficios" /></Link>
        <p>O fator decisivo pela escolha da UNIG é o pertencimento. Separamos 4 diferenciais que podem contribuir com esse conceito.</p>
      </div>
      <div className="grid-interno">
        <Link to="/financeiro"><img src={financeiro} alt="" className="financeiro" /></Link>
        <p className="txt-financeiro">Agora os polos podem tratar dos problemas financeiros interagindo diretamente com o setor responsável. Basta abrir um chamado, clicando aqui!</p>
        <a href="https://api.whatsapp.com/send/?phone=%2B552127654070&text&type=phone_number&app_absent=0" target="_blank" rel="noreferrer">
          <div className="wpp">
            <img src={whatsapp} alt="" />
            <p className="d-inline">Fale conosco através do WhatsApp.</p>
          </div>
        </a>
      </div>
    </Atalhos >
  );
}
