import { createGlobalStyle } from "styled-components";
import {
  primaryColor,
  errorColor,
  errorHoverColor,
} from '../config/colors';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }

  body {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background-color: ${primaryColor};
  }

  button {
    background-color: ${primaryColor};
    border: none;
    padding: 10px; 
    color: #fff;
  }

  button.excluir {
    background-color: ${errorColor};
  }

  button.excluir:hover {
    background-color: ${errorHoverColor};
    padding: 9px;
  }

  html, body, #root, .container {
    min-height: 86.7vh;
  }

  div.my-container {
    width: 1004px;
    margin: 0 auto;
    margin-top: 40px;
    @media (max-width: 1030px) {
      width: 900px;
    }
    @media (max-width: 1000px) {
      width: 850px;
    }
    @media (max-width: 900px) {
      width: 800px;
    }
    @media (max-width: 850px) {
      width: 700px;
    }
    @media (max-width: 800px) {
      width: 600px;
    }
    @media (max-width: 650px) {
      width: 500px;
    }
    @media (max-width: 500px) {
      width: 400px;
    }
    @media (max-width: 450px) {
      width: 300px;
    }
    @media (max-width: 400px) {
      width: 250px;
    }
  }
`;
