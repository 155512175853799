import React from 'react';
import { Comunicados } from "./Comunicados/Comunicados";
import { Container } from './styled';
import topoAdmin from './img/topo_admin.png'

export const Painel = () => {
  return (
    <Container className="my-container">
      <div className="topo-documentos">
        <img src={topoAdmin} className="img-fluid" />
      </div>
      <h1>Painel Administrativo</h1>
      <p>Você está acessando o painel de controle do ADMINISTRADOR.</p>
      <Comunicados />
    </Container>
  );
}
